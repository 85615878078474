(function(app) {
    'use strict';

    app.factory('sowingsStages', function(_apiService) {
        var fieldId;
        var sowingsPromise;

        var load = function(_accountId, _fieldId) {


            fieldId = _fieldId;

            return _apiService.fieldCrops(_accountId, _fieldId, false, true, true).then(function(response) {
                var stages = _.map(response.data, function(sowing) {
                    return _.map(_.values(sowing.stages), function(stageItem) {
                        return {name: stageItem.stage, start: stageItem.startDate, end: stageItem.endDate};
                    });
                });

                return stages;
            });
        };

        this.list = function(_accountId, _fieldId) {

            if (!sowingsPromise || (fieldId != _fieldId)) { // handle Number and String
                sowingsPromise = load(_accountId, _fieldId);
            }

            return sowingsPromise;
        };

        this.findSowingByDate = function(_accountId, _fieldId, dateStr) {
            var self = this;
            return this.list(_accountId, _fieldId).then(function(sowings) {
                var result;

                result = _.find(sowings, function(stages) {
                    return stages.length && moment.utc(dateStr).isBetween(moment.utc(stages[0].start), moment.utc(stages[stages.length-1].end), null, '[]');
                });

                return result;
            });
        };

        this.getFlatStagesFrom = function(_accountId, _fieldId, date) {
            var self = this;
            return self.list(_accountId, _fieldId).then(function(sowingsStages) {
                // join stages of all sowings together
                var allStages = _.flatten(sowingsStages);
                var filteredStages;

                // find stages from specified date
                if (angular.isDefined(date)) {
                    filteredStages = _.filter(allStages, function(stage) {
                        var dateMoment = moment.utc(date);
                        var startMoment = moment.utc(stage.start);
                        var endMoment = moment.utc(stage.end);
                        return dateMoment.isSameOrBefore(startMoment) || dateMoment.isBetween(startMoment, endMoment, null, '[]');
                    });
                } else {
                    filteredStages = allStages;
                }

                // return stages
                return filteredStages;
            });
        };

        this.getStagesDatesFrom = function(_accountId, _fieldId, date) {
            return this.getFlatStagesFrom(_accountId, _fieldId, date).then(function(filteredStages) {
                // return stages start dates
                return _.map(filteredStages, function(stage) {
                    return stage.start;
                });
            });
        };

        this.getStagesDatesStartEndFrom = function(_accountId, _fieldId, date) {
            return this.getFlatStagesFrom(_accountId, _fieldId, date).then(function(filteredStages) {
                // return stages start and end dates
                return _.map(filteredStages, function(stage) {
                    return {start: stage.start, end: stage.end, name: stage.name};
                });
            });
        };

        this.getDaysFromDateToNextStage = function(_accountId, _fieldId, dateStr) {
            var self = this;

            return self.getStagesDatesFrom(_accountId, _fieldId, dateStr).then(function(stagesDates) {
                var nextStageDateStr = _.find(stagesDates, function(stageDateStr) {
                    return moment.utc(stageDateStr).isAfter(moment.utc(dateStr));
                });

                if (angular.isUndefined(nextStageDateStr)) {
                    return 0;
                }

                var result = moment.utc(nextStageDateStr).diff(moment.utc(dateStr).startOf('day'), 'days');
                return result;
            });
        };

        this.countStagesFromDate = function(_accountId, _fieldId, dateStr) {
            var self = this;

            return self.getStagesDatesFrom(_accountId, _fieldId, dateStr).then(function(stagesDates) {
                return angular.isDefined(stagesDates) ? stagesDates.length : 0;
            });
        };

        this.getDaysFromDateToPreviousStage = function(_accountId, _fieldId, dateStr) {
            var self = this;

            return self.getStagesDatesFrom(_accountId, _fieldId).then(function(stagesDates) {

                var prevStageDateStr = _.find(stagesDates.reverse(), function(stageDateStr) {
                    return moment.utc(stageDateStr).isBefore(moment.utc(dateStr).startOf('day'));
                });

                if (angular.isUndefined(prevStageDateStr)) {
                    return 0;
                }

                var result = moment.utc(dateStr).diff(moment.utc(prevStageDateStr), 'days');
                return result;
            });
        };

        return this;
    });

} (angular.module('app')));